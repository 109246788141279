import { api } from './base';

const getBookedPeriods = (param: {
  dateFrom: Date;
  dateTo: Date;
  propertyId: number;
}) => api.post('/bookedperiods', param);

const sendQuote = (param: {
  checkInDate: Date;
  checkOutDate: Date;
  adultsCount: number;
  childrenCount: number;
  name?: string;
  email: string;
  phone?: string;
  price: number;
  discountApplied?: number;
}) => api.post('/reservations/sendquote', param);

export { getBookedPeriods, sendQuote };
