import React from 'react';
import { ReactSVG } from 'react-svg';
import styles from './Header.module.css';
import LogoWhite from 'assets/logo/LogoWhite.svg';
import { useTranslation } from 'react-i18next';

export type IHeaderProps = {};

const Header: React.FC<IHeaderProps> = ({}) => {
  const scrollTo = (selector: string) => {
    const element = document.querySelector(selector);
    if (!element) return;
    const elementOffsetY = window.scrollY + element.getBoundingClientRect().top;

    window.scrollTo(
      0,
      elementOffsetY > 0 ? elementOffsetY - 100 : elementOffsetY
    );
  };
  const { t } = useTranslation();
  return (
    <div id='home' className={styles.container}>
      <div className={styles.logoContainer}>
        <ReactSVG src={LogoWhite} className={styles.logo} />
      </div>
      <div className={styles.interaction}>
        <p>{t('header.message')}</p>
        <button onClick={() => scrollTo('#booking')}>
          {t('header.buttonText')}
        </button>
      </div>
    </div>
  );
};

export { Header };
