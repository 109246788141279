import axios from 'axios';

export const API_HOST = 'https://mobile-houses-server.herokuapp.com/';

const api = axios.create({
  baseURL: API_HOST,
  timeout: 20000,
});

export { api };
