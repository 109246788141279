import { createMuiTheme } from '@material-ui/core';
import { blue, amber } from '@material-ui/core/colors';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#83c2b4',
    },
    secondary: {
      main: '#FFF',
    },
    text: {
      primary: '#181818',
    },
    type: 'light',
  },
});
