import React from 'react';
import styles from './ContactBar.module.css';
import Email from 'assets/icons/ContactBar/Email.svg';
import MapPointer from 'assets/icons/ContactBar/MapPointer.svg';
import Phone from 'assets/icons/ContactBar/Phone.svg';
import { ReactSVG } from 'react-svg';
import { contact } from 'static/data';

export type IContactBarProps = {};

const ContactBar: React.FC<IContactBarProps> = ({}) => {
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          <a href={`tel:${contact.phone}`}>
            <ReactSVG src={Phone} />
            <span>{contact.phone}</span>
          </a>
        </li>
        <li className={styles.listItem}>
          <a href={`mailto:${contact.email}`}>
            <ReactSVG src={Email} />
            <span>{contact.email}</span>
          </a>
        </li>
        <li className={styles.listItem}>
          <a href='https://goo.gl/maps/hgY5MixPTBdyTEhE6'>
            <ReactSVG src={MapPointer} />
            <span>Put Kumenta 16</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export { ContactBar };
