import React from 'react';

export type INotFoundProps = {};

const NotFound: React.FC<INotFoundProps> = ({}) => {
  return (
    <div>
      <h1>NOT FOUND</h1>
    </div>
  );
};

export { NotFound };
