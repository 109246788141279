import React, { useEffect, useState } from 'react';
import styles from './Navigation.module.css';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import MenuGreen from 'assets/icons/MobileMenu/MenuGreen.svg';
import MenuWhite from 'assets/icons/MobileMenu/MenuWhite.svg';
import i18n from 'i18n';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

export type INavigationProps = {};

const Navigation: React.FC<INavigationProps> = ({}) => {
  const { t } = useTranslation();
  const [onTop, setOnTop] = useState<boolean>(true);
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  useEffect(() => {
    window.addEventListener('scroll', function () {
      if (window.scrollY == 0) {
        setOnTop(true);
      } else {
        if (onTop) {
          setOnTop(false);
        }
      }
    });
  }, []);

  const scrollTo = (selector: string, isMobile?: boolean) => {
    const element = document.querySelector(selector);
    if (!element) return;
    const elementOffsetY = window.scrollY + element.getBoundingClientRect().top;

    let headerHeight = isMobile ? 40 : 100;
    window.scrollTo(
      0,
      elementOffsetY > 0 ? elementOffsetY - headerHeight : elementOffsetY
    );
  };

  return (
    <div className={styles.container}>
      {/* DESKTOP NAVIGATION */}
      <div
        className={
          onTop
            ? clsx(styles.navigationContainer, styles.desktop)
            : clsx(
                styles.navigationContainer,
                styles.navigationContainerScrolling,
                styles.desktop
              )
        }
      >
        <ul className={styles.links}>
          <li onClick={() => scrollTo('#home')} className={styles.link}>
            {t('navigation.home')}
          </li>
          <li onClick={() => scrollTo('#info')} className={styles.link}>
            {t('navigation.info')}
          </li>
          <li onClick={() => scrollTo('#gallery')} className={styles.link}>
            {t('navigation.gallery')}
          </li>
          <li onClick={() => scrollTo('#offers')} className={styles.link}>
            {t('navigation.offers')}
          </li>
          <li onClick={() => scrollTo('#booking')} className={styles.link}>
            {t('navigation.booking')}
          </li>
          <li onClick={() => scrollTo('#contact')} className={styles.link}>
            {t('navigation.contact')}
          </li>
          <li className={styles.link}>
            <FormControl style={{ width: '60px' }}>
              <Select
                id='language-select'
                value={i18n.language}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  i18n.changeLanguage(event.target.value as string);
                }}
                style={{ color: onTop ? '#FFF' : '#181818', top: '-2px' }}
              >
                <MenuItem value={'en'}>EN</MenuItem>
                <MenuItem value={'hr'}>HR</MenuItem>
                <MenuItem value={'de'}>DE</MenuItem>
              </Select>
            </FormControl>
          </li>
        </ul>
      </div>
      {/* MOBILE NAVIGATION */}
      <div className={clsx(styles.hamburgerContainer, styles.mobile)}>
        <ReactSVG
          src={MenuGreen}
          onClick={() => {
            setMenuVisible(true);
          }}
        />
      </div>
      {menuVisible && (
        <div className={clsx(styles.mobileNavigationContainer, styles.mobile)}>
          <div className={clsx(styles.hamburgerContainer, styles.mobile)}>
            <ReactSVG
              src={MenuGreen}
              onClick={() => {
                setMenuVisible(false);
              }}
            />
          </div>
          <ul className={styles.mobileNavLinks}>
            <li
              onClick={() => {
                setMenuVisible(false);
                scrollTo('#home', true);
              }}
              className={styles.mobileLink}
            >
              {t('navigation.home')}
            </li>
            <li
              onClick={() => {
                setMenuVisible(false);
                scrollTo('#info', true);
              }}
              className={styles.mobileLink}
            >
              {t('navigation.info')}
            </li>
            <li
              onClick={() => {
                setMenuVisible(false);
                scrollTo('#gallery', true);
              }}
              className={styles.mobileLink}
            >
              {t('navigation.gallery')}
            </li>
            <li
              onClick={() => {
                setMenuVisible(false);
                scrollTo('#offers', true);
              }}
              className={styles.mobileLink}
            >
              {t('navigation.offers')}
            </li>
            <li
              onClick={() => {
                setMenuVisible(false);
                scrollTo('#booking', true);
              }}
              className={styles.mobileLink}
            >
              {t('navigation.booking')}
            </li>

            <li
              onClick={() => {
                setMenuVisible(false);
                scrollTo('#contact', true);
              }}
              className={styles.mobileLink}
            >
              {t('navigation.contact')}
            </li>
          </ul>
          <div className={styles.divider} />
          <ul className={styles.languageSelectContainer}>
            <li
              onClick={() => i18n.changeLanguage('en')}
              className={clsx(
                styles.mobileLink,
                styles.languageOption,
                i18n.language === 'en' ? styles.languageSelected : ''
              )}
            >
              EN
            </li>

            <li
              onClick={() => i18n.changeLanguage('hr')}
              className={clsx(
                styles.mobileLink,
                styles.languageOption,
                i18n.language === 'hr' ? styles.languageSelected : ''
              )}
            >
              HR
            </li>

            <li
              onClick={() => i18n.changeLanguage('de')}
              className={clsx(
                styles.mobileLink,
                styles.languageOption,
                i18n.language === 'de' ? styles.languageSelected : ''
              )}
            >
              DE
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export { Navigation };
