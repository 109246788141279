import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import React from 'react';
import Loader from 'react-loader-spinner';

export type ILoadingProps = {};

const Loading: React.FC<ILoadingProps> = ({}) => {
  return <Loader type='TailSpin' color='#83c2b4' height={100} width={100} />;
};

export { Loading };
