import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home } from 'views/Home';
import { PrivacyPolicy } from 'views/PrivacyPolicy';
import { HouseRules } from 'views/HouseRules';
import { NotFound } from 'views/NotFound';

function App() {
  return (
    <div className='App'>
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/privacypolicy' component={PrivacyPolicy} />
          <Route path='/houserules' component={HouseRules} />
          <NotFound />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
