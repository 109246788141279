import i18n from 'i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Info.module.css';

export type IInfoProps = {};

const Info: React.FC<IInfoProps> = ({}) => {
  const { t } = useTranslation();
  return (
    <div id='info' className={styles.container}>
      <span className={styles.heading}>{t('info.heading')}</span>
      <span className={styles.divider}></span>
      <p className={styles.text}>{i18n.t('info.text')}</p>
    </div>
  );
};

export { Info };
