import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styles from './Footer.module.css';
import Logo from 'assets/logo/Logo.svg';
import { ReactSVG } from 'react-svg';

import adress from 'assets/icons/Footer/adress.svg';
import contact from 'assets/icons/Footer/contact.svg';

import links from 'assets/icons/Footer/links.svg';
import social from 'assets/icons/Footer/social.svg';
import Facebook from 'assets/icons/Footer/Facebook.svg';
import Instagram from 'assets/icons/Footer/Instagram.svg';
import { useTranslation } from 'react-i18next';
import { contact as data } from 'static/data';

export type IFooterProps = {};

const Footer: React.FC<IFooterProps> = ({}) => {
  const { t } = useTranslation();
  return (
    <div id='contact' className={styles.container}>
      <div className={styles.content}>
        <div className={styles.footerItem}>
          <ReactSVG src={Logo} />
        </div>
        <div className={styles.footerItem}>
          <ReactSVG src={links} />
          <div className={styles.heading}>{t('footer.links')}</div>
          <div className={styles.item}>{t('footer.houseRules')}</div>
          <div className={styles.item}>{t('footer.privacyPolicy')}</div>
        </div>
        <div className={styles.footerItem}>
          <ReactSVG src={adress} />
          <div className={styles.heading}>{t('footer.adress')}</div>
          <div className={styles.item}>Put Kumenta 16</div>
          <div className={styles.item}>23210 Biograd</div>
          <div className={styles.item}>Hrvatska</div>
        </div>
        <div className={styles.footerItem}>
          <ReactSVG src={contact} />
          <div className={styles.heading}>{t('footer.contact')}</div>
          <a className={styles.item} href={`tel: ${data.phone}`}>
            {data.phone}
          </a>
          <a className={styles.item} href={`mailto: ${data.email}`}>
            {data.email}
          </a>
        </div>
        <div className={styles.footerItem}>
          <ReactSVG src={social} />
          <div className={styles.heading}>{t('footer.social')}</div>
          <div className={styles.social}>
            <a
              className={styles.item}
              href='https://www.facebook.com/mobilehomeani/'
            >
              <ReactSVG src={Facebook} />
            </a>
            {/* <a
              className={styles.item}
              href='https://www.instagram.com/explore/locations/337820830722835/mobile-home-ani/'
            >
              <ReactSVG src={Instagram} />
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Footer };
