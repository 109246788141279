import { BookNow } from 'components/BookNow/BookNow';
import { ContactBar } from 'components/ContactBar/ContactBar';
import { Footer } from 'components/Footer/Footer';
import { Header } from 'components/Header/Header';
import { ImageSlider } from 'components/ImageSlider/ImageSlider';
import { Info } from 'components/Info/Info';
import { Navigation } from 'components/Navigation/Navigation';
import React from 'react';

export type IHomeProps = {};

const Home: React.FC<IHomeProps> = ({}) => {
  return (
    <div className='container'>
      <ContactBar />
      <Navigation />
      <Header />
      <Info />
      <ImageSlider />
      <BookNow />
      <Footer />
    </div>
  );
};

export { Home };
