import React, { useEffect, useState } from 'react';
import styles from './ImageSlider.module.css';
import { ReactSVG } from 'react-svg';
import ArrowLeft from 'assets/icons/Gallery/ArrowLeft.svg';
import ArrowRight from 'assets/icons/Gallery/ArrowRight.svg';
import clsx from 'clsx';
import api from 'api';
import { Loading } from 'components/Loader/Loader';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { ChevronLeft, ChevronRight, Close } from '@material-ui/icons';

export type IImageSliderProps = {};

const ImageSlider: React.FC<IImageSliderProps> = ({}) => {
  const [current, setCurrent] = useState<number>(1);
  const [render, setRender] = useState<{
    loading: boolean;
    data: string[] | undefined;
  }>({
    loading: true,
    data: undefined,
  });

  const [fullScreen, setFullScreen] = useState<boolean>(false);

  fullScreen
    ? disableBodyScroll(document as any)
    : enableBodyScroll(document as any);

  const handlePrevious = () => {
    if (current === 0) return;
    setMoving('L');

    setTimeout(() => {
      setMoving(null);
      setCurrent(current - 1);
    }, 100);
  };
  const handleNext = () => {
    if (!render.data) return;
    if (current === render.data.length - 1) return;
    setMoving('R');
    setTimeout(() => {
      setMoving(null);
      setCurrent(current + 1);
    }, 100);
  };

  const [moving, setMoving] = useState<'L' | 'R' | null>(null);

  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  // const [loadingImage, setLoadingImage] = useState<any>({});

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [window]);

  const handleNextFullScreen = () => {
    setCurrent((current) => {
      if (render.data) {
        if (current < render.data.length - 1) {
          return current + 1;
        }
      }

      return current;
    });
  };
  const handlePreviousFullScreen = () => {
    setCurrent((current) => {
      if (render.data) {
        if (current > 0) {
          return current - 1;
        }
      }

      return current;
    });
  };

  const handleKeyDown = (e: any) => {
    switch (e.code) {
      case 'Escape':
        setFullScreen(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getImages();
  }, []);

  useEffect(() => {
    if (fullScreen) {
      document.addEventListener('keydown', handleKeyDown, false);
    } else {
      document.removeEventListener('keydown', handleKeyDown, false);
    }
  }, [fullScreen]);

  const getImages = async () => {
    try {
      const res = await api.properties.getImages(1);
      setRender({
        data: res.data.images.map((item: any) => item.url),
        loading: false,
      });

      // let loading: any = {};
      // for (let i = 0; i < res.data.images.length; i++) {
      //   loading[`${i}`] = true;
      // }
      // setLoadingImage(loading);
    } catch (e) {
      setRender({ data: undefined, loading: false });
    }
  };

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  let imageSize = 555;
  let leftOffset = 300;
  if (screenWidth < 1200) {
    imageSize = 240;
    leftOffset = 20;
  }

  if (render.loading) {
    return (
      <div className={styles.container}>
        <Loading />
      </div>
    );
  }
  if (!render.data) {
    return null;
  }

  return (
    <div id='gallery' className={styles.container}>
      <div
        className={
          moving
            ? clsx(
                moving === 'L' ? styles.movingL : styles.movingR,
                styles.images
              )
            : clsx(styles.images)
        }
        style={{ left: -current * imageSize + leftOffset }}
      >
        {render.data.map((item, index) => {
          let nextIndex = null;
          if (moving === 'R') {
            nextIndex = current + 1;
          } else if (moving === 'L') {
            nextIndex = current - 1;
          }
          return (
            <div
              key={index}
              onClick={() => {
                if (current !== index) {
                  setCurrent(index);
                }

                setFullScreen(true);
              }}
              className={
                index === current
                  ? clsx(
                      styles.imageContainer,
                      styles.currentImageContainer,
                      moving ? styles.shrinking : {}
                    )
                  : clsx(
                      styles.imageContainer,
                      index === nextIndex ? styles.growing : {}
                    )
              }
            >
              <img src={item} className={styles.image} />
              {/* {loadingImage[index] && (
                <div className={styles.imageLoading}>
                  <Loading />
                </div>
              )} */}
            </div>
          );
        })}
      </div>

      <div className={styles.controls}>
        <ReactSVG
          src={ArrowLeft}
          onClick={() => {
            if (current > 0) {
              handlePrevious();
            }
          }}
        />

        <ReactSVG
          src={ArrowRight}
          onClick={() => {
            if (!render.data) return;
            if (current < render.data.length - 1) {
              handleNext();
            }
          }}
        />
      </div>
      {fullScreen && (
        <div className={styles.fullScreenContainer}>
          <img src={render.data[current]} className={styles.fullScreenBg}></img>
          <div className={styles.fullScreenImageContainer}>
            <img
              className={styles.fullScreenImage}
              src={render.data[current]}
            ></img>
          </div>
          <div className={styles.arrowLeft}>
            <ChevronLeft
              onClick={() => handlePreviousFullScreen()}
              style={{
                fontSize: screenWidth > 1199 ? '80px' : '40px',
                color: '#FFF',
                cursor: 'pointer',
              }}
            />
          </div>
          <div className={styles.arrowRight}>
            <ChevronRight
              onClick={() => handleNextFullScreen()}
              style={{
                fontSize: screenWidth > 1199 ? '80px' : '40px',
                color: '#FFF',
                cursor: 'pointer',
              }}
            />
          </div>
          <div className={styles.closeIcon}>
            <Close
              onClick={() => setFullScreen(false)}
              style={{
                fontSize: screenWidth > 1199 ? '40px' : '20px',
                color: '#FFF',
                cursor: 'pointer',
              }}
            />
          </div>
          <div className={styles.numbering}>
            {current + 1} of {render.data.length}
          </div>
        </div>
      )}
    </div>
  );
};

export { ImageSlider };
