import React, { useEffect, useState } from 'react';
import styles from './BookNow.module.css';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  LinearProgress,
  Snackbar,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import ArrowRight from 'assets/icons/Booking/ArrowRight.svg';
import left from 'assets/icons/Booking/left.svg';
import right from 'assets/icons/Booking/right.svg';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import api from 'api';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export type IBookNowProps = {};

const useStyles = makeStyles((theme) => ({
  dayWithDotContainer: {
    position: 'relative',
  },
  dot: {
    position: 'absolute',
    height: '5px',
    width: '5px',
    borderRadius: '50%',
    backgroundColor: 'red',
    left: '50%',
    transform: 'translate(-50%)',
    bottom: '0px',
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const emailRegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
interface IPeriod {
  price: number;
  dateFrom: string;
  dateTo: string;
}
interface IDiscount {
  nights: number;
  discount: number;
}
interface IPricing {
  priceList: IPeriod[];
  cleaningFee?: number;
  petsCleaningFee?: number;
  discounts?: IDiscount[];
  discount_by_agreement?: { minNights: number; show: boolean };
}

const BookNow: React.FC<IBookNowProps> = ({}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [checkInDate, setCheckInDate] = useState<Date>(new Date());
  const [checkOutDate, setCheckOutDate] = useState<Date>();
  const [adults, setAdults] = useState<number>(1);
  const [children, setChildren] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [price, setPrice] = useState<{
    value: number;
    discountApplied: number | undefined;
  }>({ value: 0, discountApplied: undefined });

  const [pricing, setPricing] = useState<IPricing>();

  const [bookedPeriods, setBookedPeriods] = useState<
    { checkInDate: string; checkOutDate: string }[]
  >([]);

  const [errors, setErrors] = useState<any>({});
  const [submiting, setSubmiting] = useState<boolean>(false);

  const [feedback, setFeedback] = useState<{
    visible: boolean;
    message: string;
    severity: 'error' | 'warning' | 'success';
  }>({
    visible: false,
    message: '',
    severity: 'success',
  });

  const [minCheckinDate, setMinCheckInDate] = useState<Date>(
    new Date(`04-01-${checkInDate.getFullYear()}`)
  );

  useEffect(() => {
    getPricing();
  }, []);

  const handleFeedbackClose = () => {
    setFeedback({ ...feedback, visible: false });
  };

  const getPricing = async () => {
    try {
      const res = await api.properties.pricing(1);

      setPricing(res.data);
    } catch (e) {}
  };

  const isEmailValid = (email: string) => {
    if (new RegExp(emailRegExp).test(email)) {
      return true;
    } else {
      return false;
    }
  };
  const isCampOpenInSelectedPeriod = (checkIn: Date) => {
    if (
      moment(checkIn).isBetween(
        `${checkIn.getFullYear()}-04-01`,
        `${checkIn.getFullYear()}-10-15`,
        'day',
        '[]'
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onDateChange = (date: Date, type: 'checkin' | 'checkout') => {
    let cInDate = undefined;
    let cOutDate = undefined;

    if (type === 'checkin') {
      cInDate = new Date(date);
      cOutDate = checkOutDate;
      setCheckInDate(new Date(date));
      if (moment(date).add(1, 'day').isSameOrAfter(moment(checkOutDate))) {
        setCheckOutDate(undefined);
      }
    } else if (type === 'checkout') {
      cInDate = checkInDate;
      cOutDate = new Date(date);
      setCheckOutDate(new Date(date));
    }

    if (cInDate && cOutDate) {
      setPrice(calculatePrice(cInDate, cOutDate));
    }
  };

  const calculatePrice = (
    checkIn: Date,
    checkOut: Date
  ): { value: number; discountApplied: number | undefined } => {
    if (!pricing) return { value: 0, discountApplied: undefined };

    let matchingIndexes: number[] = [];

    pricing.priceList.forEach((item, index) => {
      let pDateFrom = new Date(`${checkIn.getFullYear()}-${item.dateFrom}`);
      let pDateTo = new Date(`${checkIn.getFullYear()}-${item.dateTo}`);

      if (
        moment(checkIn).isBetween(pDateFrom, pDateTo, 'day', '[]') ||
        moment(checkOut).isBetween(pDateFrom, pDateTo, 'day', '[]')
      ) {
        matchingIndexes.push(index);
      }
    });

    let periods: IPeriod[] = [];

    if (matchingIndexes.length === 0) {
      return { value: 0, discountApplied: undefined };
    }

    matchingIndexes = matchingIndexes.sort((a, b) => a - b);

    for (
      let i = matchingIndexes[0];
      i <= matchingIndexes[matchingIndexes.length - 1];
      i++
    ) {
      periods.push(pricing.priceList[i]);
    }

    //matching periods are here

    let totalPrice = 0;
    periods.forEach((item) => {
      const periodPrice = calculatePeriodPrice(item, checkIn, checkOut);
      totalPrice += periodPrice;
    });

    const numberOfDays = Math.ceil(
      moment.duration(moment(checkOut).diff(checkIn)).asDays()
    );

    let discount = undefined;
    if (pricing.discounts) {
      discount = pricing.discounts
        .sort((a, b) => b.nights - a.nights)
        .find((item) => numberOfDays >= item.nights);
    }

    return {
      value: discount ? (1 - discount.discount) * totalPrice : totalPrice,
      discountApplied: discount ? discount.discount : undefined,
    };
  };

  const calculatePeriodPrice = (
    period: IPeriod,
    checkIn: Date,
    checkOut: Date
  ): number => {
    let periodStart = new Date(`${checkIn.getFullYear()}-${period.dateFrom}`);

    let periodEnd = new Date(`${checkIn.getFullYear()}-${period.dateTo}`);

    let calculateFrom = moment(checkIn).format('YYYY-MM-DD');
    let calculateTo = moment(checkOut).format('YYYY-MM-DD');

    if (moment(checkIn).isSameOrBefore(periodStart)) {
      calculateFrom = moment(periodStart).format('YYYY-MM-DD');
    }
    if (moment(checkOut).isSameOrAfter(periodEnd)) {
      calculateTo = moment(periodEnd).format('YYYY-MM-DD');
    }
    const days = Math.ceil(
      moment.duration(moment(calculateTo).diff(calculateFrom)).asDays()
    );

    return days * period.price;
  };

  const submitForm = async () => {
    let errs = {};
    if (!checkInDate) {
      errs = { ...errs, checkInDate: t('bookNow.required') };
    }
    if (!checkOutDate) {
      errs = { ...errs, checkOutDate: t('bookNow.required') };
    }
    if (!email) {
      errs = { ...errs, email: t('bookNow.required') };
    }
    if (!isEmailValid(email)) {
      errs = { ...errs, email: t('bookNow.emailNotValid') };
    }

    if (Object.keys(errs).length !== 0) {
      setErrors({ ...errs });
      return;
    }
    setSubmiting(true);

    const request = {
      name,
      price: price.value,
      phone,
      email,
      checkInDate,
      checkOutDate: checkOutDate as Date,
      childrenCount: children,
      adultsCount: adults,
      discountApplied: price.discountApplied,
    };

    try {
      await api.reservations.sendQuote(request);
      setFeedback({
        visible: true,
        severity: 'success',
        message: t('bookNow.emailSuccess'),
      });

      setCheckOutDate(undefined);
    } catch (e) {
      setFeedback({
        visible: true,
        severity: 'error',
        message: t('bookNow.emailFail'),
      });
    } finally {
      setSubmiting(false);
    }
  };

  const onPickerViewChange = (date: MaterialUiPickersDate): Promise<void> => {
    return new Promise(async (resolve) => {
      if (date) {
        setMinCheckInDate(moment(`04-01-${date.getFullYear()}`).toDate());
      }
      resolve();
      const param = {
        dateFrom: moment(date).clone().startOf('month').toDate(),
        dateTo: moment(date).clone().endOf('month').toDate(),
      };
      await getBookedDates(param);
    });
  };

  const onDatePickerOpen = () => {
    const date = checkInDate ? checkInDate : new Date();
    const param = {
      dateFrom: moment(date).clone().startOf('month').toDate(),
      dateTo: moment(date).clone().endOf('month').toDate(),
    };
    getBookedDates(param);
  };

  const getBookedDates = async (param: {
    dateFrom: Date;
    dateTo: Date;
  }): Promise<void> => {
    return new Promise(async (resolve) => {
      try {
        const res = await api.reservations.getBookedPeriods({
          ...param,
          propertyId: 1,
        });

        setBookedPeriods(res.data.reservations);
      } catch (e) {
        setBookedPeriods([]);
      } finally {
        resolve();
      }
    });
  };
  const renderDayInPicker = (
    date: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ) => {
    if (!date) return dayComponent;

    const booked = bookedPeriods.some((item) => {
      if (
        moment(date).isBetween(
          item.checkInDate,
          item.checkOutDate,
          'day',
          '[)'
        ) &&
        moment(date).isAfter(moment(new Date()).subtract(1, 'day'))
      ) {
        return true;
      }
      return false;
    });
    if (booked && dayInCurrentMonth) {
      return (
        <div className={classes.dayWithDotContainer}>
          {dayComponent}
          <div className={classes.dot} />
        </div>
      );
    } else {
      return dayComponent;
    }
  };

  let entireMonths: IPeriod[] = [];
  let otherDates: IPeriod[] = [];
  if (pricing) {
    pricing.priceList.forEach((element) => {
      let from = new Date(`${new Date().getFullYear()}-${element.dateFrom}`);
      let to = new Date(`${new Date().getFullYear()}-${element.dateTo}`);

      if (
        Math.round(moment.duration(moment(to).diff(from)).asDays()) ===
        moment(from).daysInMonth()
      ) {
        entireMonths = [
          ...entireMonths,
          {
            ...element,
            dateFrom: from.toDateString(),
            dateTo: to.toDateString(),
          },
        ];
      } else {
        otherDates = [
          ...otherDates,
          {
            ...element,
            dateFrom: from.toDateString(),
            dateTo: to.toDateString(),
          },
        ];
      }
    });
  }

  return (
    <div className={styles.container}>
      {pricing && (
        <div id="offers" className={styles.offersContainer}>
          <h1 className={styles.heading}>{t('offers.heading')}</h1>
          <div className={styles.divider}></div>

          <div className={styles.content}>
            <div className={styles.entireMonthsOffers}>
              {entireMonths.map((item, index) => (
                <div key={index} className={styles.monthOffer}>
                  <div className={styles.monthName}>
                    {moment(item.dateFrom).toDate().toLocaleString('en-US', {
                      month: 'long',
                    })}
                  </div>
                  <div className={styles.monthPrice}>
                    {item.price} €/{t('offers.night')}
                  </div>
                </div>
              ))}
            </div>
            <h5>{t('offers.otherDates')}</h5>
            <div className={styles.otherDatesOffers}>
              {otherDates.map((item, index) => {
                let from = moment(item.dateFrom).toDate();

                let to = moment(item.dateTo).toDate();
                return (
                  <div className={styles.datesOffer} key={index}>
                    <div className={styles.datesOfferPeriod}>
                      {`${from.getDate()}.${
                        from.getMonth() + 1
                      }. - ${to.getDate()}.${to.getMonth() + 1}.`}
                    </div>
                    <div className={styles.datesOfferPrice}>{item.price} €</div>
                  </div>
                );
              })}
            </div>
            {pricing.discount_by_agreement &&
              pricing.discount_by_agreement.show &&
              pricing.discount_by_agreement.minNights > 0 && (
                <p className={styles.discountByAgreement}>
                  {t('offers.discountByAgreement', {
                    minNights: pricing.discount_by_agreement.minNights,
                  })}
                </p>
              )}
            <div className={styles.divider} />

            {pricing.discounts && pricing.discounts.length > 0 && (
              <>
                <h5>{t('offers.discounts')}</h5>
                <div className={styles.discounts}>
                  {pricing.discounts.map((item, index) => {
                    return (
                      <div key={index} className={styles.discount}>
                        <div className={styles.discountNights}>
                          {t('offers.minNights', { value: item.nights })}
                        </div>
                        <div className={styles.discountValue}>
                          {item.discount * 100}%
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            {pricing.cleaningFee && pricing.petsCleaningFee && (
              <p className={styles.cleaningFee}>
                {t('offers.finalCleaningFeeWithPets', {
                  fee: pricing.cleaningFee,
                  pets: pricing.petsCleaningFee,
                })}
              </p>
            )}
            {pricing.cleaningFee && !pricing.petsCleaningFee && (
              <p className={styles.cleaningFee}>
                {t('offers.finalCleaningFee', {
                  fee: pricing.cleaningFee,
                })}
              </p>
            )}
          </div>
        </div>
      )}
      <div id="booking" className={styles.bookNowContainer}>
        <h1 className={styles.heading}>{t('bookNow.heading')}</h1>
        <div className={styles.divider}></div>
        <p className={styles.message}>{t('bookNow.message')}</p>
        {submiting && <LinearProgress />}

        <div className={styles.content}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4} style={{ position: 'relative' }}>
                <DatePicker
                  disabled={submiting}
                  disablePast
                  disableToolbar
                  style={{ width: '90%' }}
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="checkInDate"
                  label={t('bookNow.checkIn').toUpperCase()}
                  placeholder={t('bookNow.checkIn').toUpperCase()}
                  value={checkInDate}
                  onMonthChange={onPickerViewChange}
                  onYearChange={onPickerViewChange}
                  onOpen={() => {
                    onDatePickerOpen();
                  }}
                  autoOk={true}
                  renderDay={renderDayInPicker}
                  onChange={(date) => {
                    if (!date) return;
                    onDateChange(date, 'checkin');
                    setErrors({ ...errors, checkInDate: undefined });
                  }}
                  error={errors.checkInDate ? true : false}
                  helperText={errors.checkInDate ? errors.checkInDate : null}
                />
                <div className={styles.arrow}>
                  <ReactSVG src={ArrowRight} />
                </div>
              </Grid>
              <Grid item xs={12} lg={4}>
                <DatePicker
                  minDate={moment(checkInDate).add(1, 'day').toDate()}
                  maxDate={new Date(`${checkInDate.getFullYear()}-10-15`)}
                  disableToolbar
                  autoOk={true}
                  disabled={submiting}
                  style={{ width: '90%' }}
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="checkOutDate"
                  label={t('bookNow.checkOut').toUpperCase()}
                  placeholder={t('bookNow.checkOut').toUpperCase()}
                  value={
                    checkOutDate
                      ? checkOutDate.getTime() >= checkInDate.getTime()
                        ? checkOutDate
                        : null
                      : null
                  }
                  onMonthChange={onPickerViewChange}
                  onYearChange={onPickerViewChange}
                  renderDay={renderDayInPicker}
                  onOpen={() => {
                    onDatePickerOpen();
                  }}
                  onChange={(date) => {
                    if (!date) {
                      return;
                    }
                    onDateChange(date, 'checkout');
                    setErrors({ ...errors, checkOutDate: undefined });
                  }}
                  error={errors.checkOutDate ? true : false}
                  helperText={errors.checkOutDate ? errors.checkOutDate : null}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <div className={styles.counters}>
                  <div className={styles.counterContainer}>
                    <div className={styles.counterHeading}>
                      <span>{t('bookNow.adults').toUpperCase()}</span>
                    </div>
                    <div className={styles.counterBody}>
                      <ReactSVG
                        onClick={() =>
                          !submiting && adults > 1 && setAdults(adults - 1)
                        }
                        src={left}
                      />
                      <span>{adults}</span>
                      <ReactSVG
                        onClick={() =>
                          !submiting && adults < 6 && setAdults(adults + 1)
                        }
                        src={right}
                      />
                    </div>
                  </div>
                  <div className={styles.counterContainer}>
                    <div className={styles.counterHeading}>
                      <span>{t('bookNow.children').toUpperCase()}</span>
                    </div>
                    <div className={styles.counterBody}>
                      <ReactSVG
                        onClick={() =>
                          !submiting &&
                          children > 0 &&
                          setChildren(children - 1)
                        }
                        src={left}
                      />
                      <span>{children}</span>
                      <ReactSVG
                        onClick={() =>
                          !submiting &&
                          children < 6 &&
                          setChildren(children + 1)
                        }
                        src={right}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  id="name"
                  label={t('bookNow.name').toUpperCase()}
                  style={{ width: '90%' }}
                  onChange={(e) => setName(e.target.value)}
                  disabled={submiting}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  id="email"
                  label={t('bookNow.email').toUpperCase()}
                  style={{ width: '90%' }}
                  onFocus={() => setErrors({ ...errors, email: undefined })}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={() => {
                    if (email.length === 0) {
                      setErrors({ ...errors, email: t('bookNow.required') });
                    } else if (!isEmailValid(email)) {
                      setErrors({
                        ...errors,
                        email: t('bookNow.emailNotValid'),
                      });
                    }
                  }}
                  error={errors.email ? true : false}
                  helperText={errors.email ? errors.email : null}
                  disabled={submiting}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  id="phone"
                  label={t('bookNow.phoneNumber').toUpperCase()}
                  style={{ width: '90%' }}
                  onChange={(e) => setPhone(e.target.value)}
                  disabled={submiting}
                />
              </Grid>

              {price.value > 0 && (
                <Grid item xs={12}>
                  <div className={styles.priceContainer}>
                    <div className={styles.price}>
                      <span className={styles.label}>
                        {t('bookNow.price')}:
                      </span>
                      <span className={styles.amount}>
                        {price.value.toFixed(2)} €
                      </span>
                      {price.discountApplied && (
                        <span className={styles.discountApplied}>
                          {t('bookNow.discountApplied', {
                            value: price.discountApplied * 100,
                          })}
                        </span>
                      )}
                    </div>
                  </div>
                </Grid>
              )}

              <Grid item xs={12}>
                {!isCampOpenInSelectedPeriod(checkInDate) && (
                  <p className={styles.campNotOpen}>
                    {t('bookNow.campClosed')}
                  </p>
                )}
                <Button
                  className={styles.sendQuote}
                  color="primary"
                  disabled={
                    submiting || !isCampOpenInSelectedPeriod(checkInDate)
                  }
                  variant="contained"
                  onClick={() =>
                    isCampOpenInSelectedPeriod(checkInDate) && submitForm()
                  }
                >
                  {submiting && <CircularProgress size={14} />}
                  {!submiting && t('bookNow.sendQuote')}
                </Button>
                {price.value > 0 && (
                  <div className={styles.priceDesc}>
                    <div className={styles.priceDescHeading}>
                      {t('bookNow.included_in_price')}
                    </div>
                    <ul className={styles.priceDescList}>
                      <li className={styles.priceDescItem}>
                        {t('bookNow.incl_one')}
                      </li>
                      <li className={styles.priceDescItem}>
                        {t('bookNow.incl_two')}
                      </li>
                      <li className={styles.priceDescItem}>
                        {t('bookNow.incl_three')}
                      </li>
                    </ul>
                    <div className={styles.priceDescHeading}>
                      {t('bookNow.not_included_in_price')}
                    </div>
                    <ul className={styles.priceDescList}>
                      <li className={styles.priceDescItem}>
                        {t('bookNow.not_incl_one')}
                      </li>
                      <li className={styles.priceDescItem}>
                        {t('bookNow.not_incl_two')}
                      </li>
                    </ul>
                    <div className={styles.times}>{`${t(
                      'bookNow.checkIn'
                    )}: 15:00h`}</div>
                    <div className={styles.times}>{`${t(
                      'bookNow.checkOut'
                    )}: 11:00h`}</div>
                  </div>
                )}
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={feedback.visible}
          autoHideDuration={3000}
          onClose={handleFeedbackClose}
        >
          <Alert onClose={handleFeedbackClose} severity={feedback.severity}>
            {feedback.message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export { BookNow };
